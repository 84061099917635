import $ from 'jquery';
import 'slick-carousel';

jQuery(function() {
    //  Grab Cursor when grabbing a Slide
    $(".slick-slide").on("mousedown touchstart", function() {
        $(this).addClass('grabbing');
    });

    $(".slick-slide").on("mouseup touchend", function() {
        $(this).removeClass('grabbing');
    });

    $('.slider-home').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        infinite: true,
        asNavFor: '.slider-home-nav'
    });

    $('.slider-home-nav').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        fade: false,
        infinite: true,
        asNavFor: '.slider-home',
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    });

    
    $('.slick-realisations').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        prevArrow: $('.last-realisations-prev'),
        nextArrow: $('.last-realisations-next'),
        fade: false,
        infinite: true,
        centerMode: true,
        focusOnSelect: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    });

    
    $('.slick-reviews').slick({
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: false,
        fade: false,
        dots: true,
        infinite: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    });
});